// Gear available to rent by sport
const GearData = [
  {
    id: 1, // Primary Key
    value: 0,
    sportType: "Soccer", // Item category
    itemName: "Soccer Ball", // Item name
    itemPrice: 1.75, // Cost to rent 1 item
    maxItems: 5, // Max number that can be rented per reservation
  },
  {
    id: 2,
    value: 0,
    sportType: "Soccer",
    itemName: "Soccer Cleats",
    itemPrice: 3,
    maxItems: 12,
  },
  {
    id: 3,
    value: 0,
    sportType: "Basketball",
    itemName: "Basketball",
    itemPrice: 1.75,
    maxItems: 5,
  },
  {
    id: 4,
    value: 0,
    sportType: "Volleyball",
    itemName: "Volleyball",
    itemPrice: 1.75,
    maxItems: 5,
  },
  {
    id: 5,
    value: 0,
    sportType: "Table Tennis",
    itemName: "Table Tennis Ball",
    itemPrice: 0.5,
    maxItems: 8,
  },
  {
    id: 6,
    value: 0,
    sportType: "Table Tennis",
    itemName: "Racket",
    itemPrice: 2,
    maxItems: 3,
  },
  {
    id: 7,
    value: 0,
    sportType: "Squash",
    itemName: "Squash Ball",
    itemPrice: 0.75,
    maxItems: 5,
  },
  {
    id: 8,
    value: 0,
    sportType: "Squash",
    itemName: "Racket",
    itemPrice: 2.5,
    maxItems: 4,
  },
  {
    id: 9,
    value: 0,
    sportType: "Badminton",
    itemName: "Shuttlecock",
    itemPrice: 0.5,
    maxItems: 10,
  },
  {
    id: 10,
    value: 0,
    sportType: "Badminton",
    itemName: "Racket",
    itemPrice: 2.5,
    maxItems: 4,
  },
  {
    id: 11,
    value: 0,
    sportType: "Frisbee",
    itemName: "Frisbee",
    itemPrice: 0.5,
    maxItems: 5,
  },
  {
    id: 12,
    value: 0,
    sportType: "Soccer",
    itemName: "Training Cones",
    itemPrice: 0.15,
    maxItems: 75,
  },
  {
    id: 13,
    value: 0,
    sportType: "Cricket",
    itemName: "Cricket Bat",
    itemPrice: 3,
    maxItems: 5,
  },
  {
    id: 14,
    value: 0,
    sportType: "Cricket",
    itemName: "Cricket Ball",
    itemPrice: 0.75,
    maxItems: 5,
  },
  {
    id: 15,
    value: 0,
    sportType: "Football",
    itemName: "Football",
    itemPrice: 1.75,
    maxItems: 3,
  },
  {
    id: 16,
    value: 0,
    sportType: "Football",
    itemName: "Football Cleats",
    itemPrice: 3,
    maxItems: 12,
  },
  {
    id: 17,
    value: 0,
    sportType: "Baseball",
    itemName: "Baseball Bat",
    itemPrice: 3,
    maxItems: 5,
  },
  {
    id: 17,
    value: 0,
    sportType: "Baseball",
    itemName: "Baseball",
    itemPrice: 0.5,
    maxItems: 10,
  },
  {
    id: 18,
    value: 0,
    sportType: "Bowling",
    itemName: "Bowling Shoes",
    itemPrice: 2.25,
    maxItems: 6,
  },
  {
    id: 18,
    value: 0,
    sportType: "Bowling",
    itemName: "Bowling Glove",
    itemPrice: 3.75,
    maxItems: 3,
  },
];

export default GearData;
