const FacilityData = [
  {
    _id: { $oid: "00" },
    facilityId: "00",
    facilityName: "Student Recreational Sports Center",
    facilityLocation: {
      place_id: "ChIJtxjOXrpmbIgRzbikOxbr4-0",
      street: "Student Recreational Sports Center (SRSC), East Law Lane",
      city: " Bloomington",
      state: " IN",
      country: " USA",
    },
    facilitySports: "Soccer",
    facilityInformation: "Soccer Field #06A",
    reservationPeriodStart: "13",
    reservationPeriodEnd: "20",
    latitude: { $numberDouble: "39.1734" },
    longitude: { $numberDouble: "-86.5123139" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "01" },
    facilityId: "01",
    facilityName: "France A. Córdova Recreational Sports Center",
    facilityLocation: {
      place_id: "ChIJc3NYhLXiEogRzh1avYzXIBc",
      street:
        "France A. Córdova Recreational Sports Center, North Martin Jischke Drive",
      city: " West Lafayette",
      state: " IN",
      country: " USA",
    },
    facilitySports: "Basketball",
    facilityInformation: "Basketball Court A",
    reservationPeriodStart: "8",
    reservationPeriodEnd: "16",
    latitude: { $numberDouble: "40.4285278" },
    longitude: { $numberDouble: "-86.9220199" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "02" },
    facilityId: "02",
    facilityName: "University of Colorado Boulder Recreation Center",
    facilityLocation: {
      place_id: "ChIJ6US2rTPsa4cR2cVOiUs7VHE",
      street:
        "University Of Colorado Boulder Recreation Center, Pleasant Street",
      city: " Boulder",
      state: " CO",
      country: " USA",
    },
    facilitySports: "Bowling",
    facilityInformation: "Bowling Lane #002",
    reservationPeriodStart: "14",
    reservationPeriodEnd: "18",
    latitude: { $numberDouble: "40.0102234" },
    longitude: { $numberDouble: "-105.2693947" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "03" },
    facilityId: "03",
    facilityName: "UCLA REC",
    facilityLocation: {
      place_id: "ChIJOz-HLIm8woARBQSw84j-Rb8",
      street: "UCLA REC, Westwood Plaza",
      city: " Los Angeles",
      state: " CA",
      country: " USA",
    },
    facilitySports: "Volleyball",
    facilityInformation: "Volleyball Court 02B",
    reservationPeriodStart: "13",
    reservationPeriodEnd: "18",
    latitude: { $numberDouble: "34.071564" },
    longitude: { $numberDouble: "-118.44534" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "04" },
    facilityId: "04",
    facilityName: "University of Cincinnati Campus Recreation Center",
    facilityLocation: {
      place_id: "ChIJ6ZTuYouzQYgRy34GyzMg0Ag",
      street:
        "The University of Cincinnati Campus Recreation Center, Bearcat Way",
      city: " Cincinnati",
      state: " OH",
      country: " USA",
    },
    facilitySports: "Football",
    facilityInformation: "Football Field A",
    reservationPeriodStart: "9",
    reservationPeriodEnd: "15",
    latitude: { $numberDouble: "39.13216" },
    longitude: { $numberDouble: "-84.51500899999999" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "05" },
    facilityId: "05",
    facilityName: "UCSD Rec Coast Tennis Courts",
    facilityLocation: {
      place_id: "ChIJuSKG70sH3IARQEHFsa-MMfA",
      street: "UCSD Rec Coast Tennis Courts",
      city: " La Jolla",
      state: " CA",
      country: " USA",
    },
    facilitySports: "Table Tennis",
    facilityInformation: "Indoor Table L1",
    reservationPeriodStart: "5",
    reservationPeriodEnd: "20",
    latitude: { $numberDouble: "32.870471" },
    longitude: { $numberDouble: "-117.2484133" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "06" },
    facilityId: "06",
    facilityName: "The Ohio State University RPAC",
    facilityLocation: {
      place_id: "ChIJB-ZAQ5SOOIgRM79SReqgWlI",
      street: "The Ohio State University RPAC, Annie and John Glenn Avenue",
      city: " Columbus",
      state: " OH",
      country: " USA",
    },
    facilitySports: "Soccer",
    facilityInformation: "Soccer Field #00",
    reservationPeriodStart: "13",
    reservationPeriodEnd: "17",
    latitude: { $numberDouble: "39.9994014" },
    longitude: { $numberDouble: "-83.0182686" },
    __v: { $numberInt: "0" },
  },
  {
    _id: { $oid: "07" },
    facilityId: "07",
    facilityName: "Florida State University Rec SportsPlex",
    facilityLocation: {
      place_id: "ChIJof77drz07IgRfdFK7j-i8IQ",
      street: "Florida State University Rec SportsPlex, Tyson Road",
      city: " Tallahassee",
      state: " FL",
      country: " USA",
    },
    facilitySports: "Baseball",
    facilityInformation: "Baseball Field C12",
    reservationPeriodStart: "11",
    reservationPeriodEnd: "19",
    latitude: { $numberDouble: "30.4204302" },
    longitude: { $numberDouble: "-84.3388995" },
    __v: { $numberInt: "0" },
  },
];

export default FacilityData;
