const ReservedSlotsData = [
  {
    _id: "00",
    facilityID: "00",
    intime: 13,
    outtime: 14,
  },
  {
    _id: "01",
    facilityID: "00",
    intime: 17,
    outtime: 18,
  },
  {
    _id: "01",
    facilityID: "01",
    intime: 10,
    outtime: 11,
  },
];

export default ReservedSlotsData;
